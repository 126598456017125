const homeIcon = {
    name: 'home-icon',
    style: 'line',
    path:
      '<path d="M536.562,616.808l-9.638-8.066a3.221,3.221,0,0,0-4.113,0l-9.639,8.066a3.228,3.228,0,0,0-.964,3.581,3.2,3.2,0,0,0,1.414,1.694v8.5a3.228,3.228,0,0,0,3.213,3.226H532.9a3.228,3.228,0,0,0,3.211-3.226v-8.5a3.234,3.234,0,0,0,.45-5.275Zm-3.662,2.484v11.292h-4.821v-8.066H521.65v8.066h-4.819V619.292h-1.607l9.639-8.066,9.639,8.066H532.9Z" transform="translate(-512.012 -608)"/>',
    // viewBox="0 0 25.71 25.81"
  };
  const registoIcon = {
    name: 'registo-icon',
    style: 'line',
    path:
      '<path d="M363.214,374.557A13.024,13.024,0,0,1,355.568,372a.826.826,0,0,1,0-1.351,12.774,12.774,0,0,1,7.661-2.54,8.02,8.02,0,0,0,1.595-.161c.185-.037.352-.111.529-.161a8.01,8.01,0,0,0,.965-.3c.193-.08.367-.193.551-.288a8.44,8.44,0,0,0,.8-.436c.15-.1.278-.223.421-.331a8.47,8.47,0,0,0,.693-.545l-.014-.014a8.06,8.06,0,1,0-11.936-.982h0c.039.053.09.095.129.146a8.3,8.3,0,0,0,.74.836l-.016.014a15.6,15.6,0,0,0-4.039,2.171,4.066,4.066,0,0,0,0,6.528,15.966,15.966,0,0,0,9.544,3.167,1.624,1.624,0,0,0,1.658-1.608,1.593,1.593,0,0,0-1.638-1.59ZM358.4,360.071a4.823,4.823,0,1,1,4.823,4.823A4.823,4.823,0,0,1,358.4,360.071Zm17.71,11.255H374.5v-1.608a1.608,1.608,0,0,0-3.216,0v1.608H369.68a1.608,1.608,0,0,0,0,3.216h1.608v1.608a1.608,1.608,0,1,0,3.216,0v-1.608h1.608a1.608,1.608,0,1,0,0-3.216Z" transform="translate(-352.009 -351.948)"/>',
    // viewbox: "0 0 25.71 25.809"
  };
  const gestaoIcon = {
    name: 'gestao-icon',
    style: 'line',
    path:
      '<path d="M545.3,69.208h9.357a3.877,3.877,0,0,0,7.324,0h1.545a1.3,1.3,0,1,0,0-2.6h-1.545a3.877,3.877,0,0,0-7.324,0H545.3a1.3,1.3,0,1,0,0,2.6Zm13.019-2.6a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,558.321,66.6Zm5.208,13.019h-1.545a3.877,3.877,0,0,0-7.324,0H545.3a1.3,1.3,0,1,0,0,2.6h9.357a3.877,3.877,0,0,0,7.324,0h1.545a1.3,1.3,0,1,0,0-2.6Zm-5.208,2.6a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,558.321,82.226Zm5.208-9.113h-9.357a3.877,3.877,0,0,0-7.324,0H545.3a1.3,1.3,0,1,0,0,2.6h1.545a3.88,3.88,0,0,0,7.344-.046,1.229,1.229,0,0,0,.224.046h9.113a1.3,1.3,0,1,0,0-2.6Zm-13.019,2.6a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,550.509,75.717Z" transform="translate(-544 -64)"/>',
    // viewbox: "0 0 20.83 20.831"
  };
  const operacoesIcon = {
    name: 'operacoes-icon',
    style: 'line',
    path:
      '<path d="M696.336,78.007l-1.2-.7,1.2-.634a3.335,3.335,0,0,0,1.224-4.549l-1.668-2.9A3.347,3.347,0,0,0,691.314,68l-1.2.694.033-1.361A3.335,3.335,0,0,0,686.811,64h-3.335a3.335,3.335,0,0,0-3.335,3.335v1.377L678.974,68a3.343,3.343,0,0,0-4.569,1.226l-1.667,2.9a3.335,3.335,0,0,0,1.224,4.549l1.181.682-1.181.647a3.335,3.335,0,0,0-1.224,4.549l1.667,2.9a3.343,3.343,0,0,0,4.569,1.226l1.2-.694-.033,1.357a3.335,3.335,0,0,0,3.335,3.335h3.335a3.335,3.335,0,0,0,3.335-3.335V85.968l1.167.712a3.347,3.347,0,0,0,4.571-1.226l1.668-2.9a3.335,3.335,0,0,0-1.216-4.546ZM693.458,80.2l1.206.7L693,83.8l-1.2-.694a3.281,3.281,0,0,0-3.286,0l-.047.027a3.267,3.267,0,0,0-1.647,2.835v1.382H683.48V85.968a3.274,3.274,0,0,0-1.646-2.835l-.048-.027a3.278,3.278,0,0,0-3.287,0l-1.2.694-1.668-2.9,1.176-.689a3.313,3.313,0,0,0,0-5.74l-1.182-.682,1.668-2.9,1.2.694a3.28,3.28,0,0,0,3.287,0l.047-.027a3.266,3.266,0,0,0,1.647-2.835V67.335h3.335v1.377a3.271,3.271,0,0,0,1.647,2.835l.047.027a3.277,3.277,0,0,0,3.287,0l1.2-.694,1.668,2.9-1.206.7a3.27,3.27,0,0,0-1.634,2.835v.04a3.265,3.265,0,0,0,1.641,2.845Zm-8.314-6.193a3.335,3.335,0,1,0,3.335,3.335A3.335,3.335,0,0,0,685.144,74.005Z" transform="translate(-672.294 -64)"/>',
    // viewbox: "0 0 25.71 26.68"
  };
  const documentacaoIcon = {
    name: 'documentacao-icon',
    style: 'line',
    path:
      '<path d="M369.528,438.037h-7.346a1.836,1.836,0,0,1-1.836-1.836h0a1.836,1.836,0,0,1,1.836-1.836h7.346a1.836,1.836,0,0,1,1.836,1.836h0A1.836,1.836,0,0,1,369.528,438.037Zm0-5.509h-7.346a1.836,1.836,0,0,1-1.836-1.836h0a1.836,1.836,0,0,1,1.836-1.836h7.346a1.836,1.836,0,0,1,1.836,1.836h0A1.836,1.836,0,0,1,369.528,432.528Zm0-16.528H356.673A3.684,3.684,0,0,0,353,419.673V441.71a3.684,3.684,0,0,0,3.673,3.673h18.364a3.684,3.684,0,0,0,3.673-3.673V425.182A9.182,9.182,0,0,0,369.528,416Zm5.509,25.71H356.673V419.673h9.182v9.182h9.182Zm-5.509-16.528v-5.509a5.526,5.526,0,0,1,5.509,5.509Z" transform="translate(-353 -416)"/>',
    // viewbox: "0 0 25.71 29.383"
  };
  const estatisticasIcon = {
    name: 'estatisticas-icon',
    style: 'line',
    path:
      '<path d="M755.384,466.234H738.562V449.412a1.3,1.3,0,1,0-2.589,0v18.12a1.3,1.3,0,0,0,1.294,1.294h18.12a1.3,1.3,0,1,0,0-2.591Zm-10.354-2.589a1.294,1.294,0,0,1-1.077-.576l-2.589-3.882a1.295,1.295,0,1,1,2.154-1.434l1.227,1.831,1.674-4.187a1.295,1.295,0,0,1,1.92-.6l2.589,1.725L754.2,448.9a1.294,1.294,0,1,1,2.378,1.02l-3.883,9.059a1.295,1.295,0,0,1-1.907.567l-2.556-1.7-2,4.991a1.3,1.3,0,0,1-1.068.806,1.173,1.173,0,0,1-.138.011Z" transform="translate(-735.973 -448.075)"/>',
    // viewbox: "0 0 20.71 20.75"
  };
  const noticiasIcon = {
    name: 'noticias-icon',
    style: 'line',
    path:
      '<path d="M396.855,256a12.855,12.855,0,1,0,12.855,12.855A12.855,12.855,0,0,0,396.855,256Zm0,22.5a9.617,9.617,0,0,1-7.769-3.949c.106-.037.057-.142-.262-.362a9.551,9.551,0,0,1-.688-1.236,38.573,38.573,0,0,1,.685-4.094,1.52,1.52,0,0,0-1.464-1.6,9.535,9.535,0,0,1,1.065-3.072,8.7,8.7,0,0,1,2.005-.153,1.319,1.319,0,0,0,1.176-.95,3.026,3.026,0,0,0,0-2.312,9.641,9.641,0,1,1,5.251,17.724Zm6.427-8.034c-3.214,3.214,1.607-4.821,0-6.428a12.181,12.181,0,0,0-6.427-3.214,3.456,3.456,0,0,0-3.214,3.214c0,1.607,3.214,1.607,4.821,1.607s3.214-1.607,1.607,1.607-3.214,0-6.427,0-1.607,6.427,1.607,4.821,3.214,6.427,6.427,3.214S406.5,267.248,403.282,270.462Z" transform="translate(-384 -256)"/>',
    // viewBox="0 0 25.71 25.71"
  };
  const loginIcon = {
    name: 'login-icon',
    style: 'line',
    path:
      '<path id="key_1" d="M238.324,128a6.487,6.487,0,0,0-6.448,6.519,7.133,7.133,0,0,0,.142,1.4l-7.118,7.2a2.595,2.595,0,0,0,0,3.677l1.29,1.3a2.533,2.533,0,0,0,3.637,0l.838-.834a2.647,2.647,0,0,0,1.211.3,2.557,2.557,0,0,0,1.825-.769l1.29-1.3a2.621,2.621,0,0,0,.464-3.064l1.509-1.526a6.747,6.747,0,0,0,1.366.143A6.519,6.519,0,1,0,238.324,128Zm1.29,10.2a3.533,3.533,0,0,1-1.29.235,3.781,3.781,0,0,1-2.011-.574l-1.858,1.878-2.579,2.608,1.29,1.3-1.29,1.3-1.29-1.3-2.579,2.608-1.29-1.3,6.448-6.519,1.858-1.878a3.884,3.884,0,0,1-.569-2.034,3.67,3.67,0,0,1,.232-1.3,3.832,3.832,0,0,1,3.637-2.608,3.912,3.912,0,0,1,1.29,7.589Zm-1.29-4.981a1.3,1.3,0,1,0,1.29,1.3,1.3,1.3,0,0,0-1.29-1.3Z" transform="translate(-224.136 -128)"/>',
    // viewBox="0 0 20.711 20.862"
  };
  const menuIcon = {
    name: 'menu-icon',
    style: 'line',
    path:
      '<path id="menu_1" d="M692.984,614.452H675.726A1.726,1.726,0,0,1,674,612.726h0A1.726,1.726,0,0,1,675.726,611h17.258a1.726,1.726,0,0,1,1.726,1.726h0A1.726,1.726,0,0,1,692.984,614.452Zm0,6.9H675.726A1.726,1.726,0,0,1,674,619.629h0a1.726,1.726,0,0,1,1.726-1.726h17.258a1.726,1.726,0,0,1,1.726,1.726h0A1.726,1.726,0,0,1,692.984,621.355Zm0,6.9H675.726A1.726,1.726,0,0,1,674,626.532h0a1.726,1.726,0,0,1,1.726-1.726h17.258a1.726,1.726,0,0,1,1.726,1.726h0A1.726,1.726,0,0,1,692.984,628.258Z" transform="translate(-674 -611)"/>',
    // viewBox="0 0 20.71 17.258"
  };
  const arrowDownIcon = {
    name: 'arrow-down-icon',
    style: 'line',
    path:
      '<path d="M425.511,722.707l14.477-14.655a2.341,2.341,0,0,0,0-3.294,2.283,2.283,0,0,0-3.255,0l-16.1,16.3a2.341,2.341,0,0,0,0,3.292l16.1,16.3a2.278,2.278,0,0,0,3.255,0,2.341,2.341,0,0,0,0-3.292Z" transform="translate(-704.076 440.665) rotate(-90)" fill-rule="evenodd"/>',
    // viewBox="0 0 37.262 20.71"
  };
  const perfilIcon = {
    name: 'perfil-icon',
    style: 'line',
    path:
      '<path d="M340.19,366.752a14.861,14.861,0,0,0-3.738-2.005,7.37,7.37,0,0,0-4.225-12.691,6.782,6.782,0,0,0-1.743,0,7.371,7.371,0,0,0-4.226,12.691,14.855,14.855,0,0,0-3.736,2.005,3.729,3.729,0,0,0,0,6.011,14.85,14.85,0,0,0,17.669,0,3.732,3.732,0,0,0,0-6.011ZM326.906,359.4a4.425,4.425,0,0,1,4-4.41,3.593,3.593,0,0,1,.92,0,4.439,4.439,0,1,1-4.916,4.41Zm11.517,10.986a11.876,11.876,0,0,1-14.135,0,.774.774,0,0,1,0-1.256,11.881,11.881,0,0,1,14.135,0,.775.775,0,0,1,0,1.256Z" transform="translate(-321 -352)"/>',
    // viewBox="0 0 20.71 23.677"
  };
  const contactoIcon = {
    name: 'contacto-icon',
    style: 'line',
    path:
      '<path d="M54.5,485.214v12.855H35.214V485.214H54.5m0-3.214H35.214A3.214,3.214,0,0,0,32,485.214v12.855a3.214,3.214,0,0,0,3.214,3.214H54.5a3.214,3.214,0,0,0,3.214-3.214V485.214A3.214,3.214,0,0,0,54.5,482Zm-9.641,12.855a1.607,1.607,0,0,1-.964-.321l-6.428-4.821a1.607,1.607,0,0,1,1.928-2.571l5.463,4.1,5.463-4.1a1.607,1.607,0,0,1,1.928,2.571l-6.428,4.821A1.607,1.607,0,0,1,44.855,494.855Z" transform="translate(-32 -482)"/>',
    // viewBox="0 0 25.71 19.282"
  };
  const alterarPasswordIcon = {
    name: 'alterar-password-icon',
    style: 'line',
    path:
      '<path d="M339.3,714.329a1.276,1.276,0,0,0-1.267,1.094,7.766,7.766,0,0,1-9.351,6.367,7.637,7.637,0,0,1-4.116-2.369h1.905a1.274,1.274,0,1,0,0-2.546h-5.177A1.282,1.282,0,0,0,320,718.147v5.1a1.294,1.294,0,1,0,2.589,0v-2.2a10.39,10.39,0,0,0,18-5.267,1.285,1.285,0,0,0-1.292-1.45Zm.1-10.185a1.287,1.287,0,0,0-1.294,1.272v2.19a10.431,10.431,0,0,0-9.182-3.373,10.323,10.323,0,0,0-8.821,8.659,1.294,1.294,0,0,0,2.563.352,7.788,7.788,0,0,1,13.45-4.013h-1.881a1.274,1.274,0,1,0,0,2.546h5.177a1.308,1.308,0,0,0,.867-.33,1.271,1.271,0,0,0,.427-.942v-5.093a1.287,1.287,0,0,0-1.306-1.268Z" transform="translate(-320 -704.136)"/>',
    // viewBox="0 0 20.71 20.404"
  };
  const terminarSessaoIcon = {
    name: 'terminar-sessao-icon',
    style: 'line',
    path:
      '<path d="M146.325,325.227a1.464,1.464,0,0,0-2.4,1.13v.015a1.521,1.521,0,0,0,.532,1.13,7.395,7.395,0,1,1-9.939,0,1.522,1.522,0,0,0,.534-1.13,1.456,1.456,0,0,0-1.479-1.453,1.488,1.488,0,0,0-1.007.382,10.256,10.256,0,0,0-3.341,9.042,10.363,10.363,0,0,0,20.616-1.408,10.2,10.2,0,0,0-3.519-7.706Zm-6.833,7.707h0a1.477,1.477,0,0,1-1.479-1.468V321.191a1.476,1.476,0,0,1,1.479-1.468h0a1.477,1.477,0,0,1,1.479,1.468v10.274a1.479,1.479,0,0,1-1.479,1.468Z" transform="translate(-129.135 -319.723)"/>',
    // viewBox="0 0 20.71 23.481"
  };
  const consultarIcon = {
    name: 'consultar-icon',
    style: 'line',
    path:
      '<path d="M500.3,658.564l-6.243-6.272a7.756,7.756,0,1,0-1.824,1.839l6.243,6.272a1.3,1.3,0,1,0,1.825-1.839Zm-12.558-5.584a5.2,5.2,0,1,1,5.174-5.2A5.2,5.2,0,0,1,487.747,652.98Z" transform="translate(-479.979 -640.088)"/>',
    // viewBox="0 0 20.71 20.697"
  };
  const criarIcon = {
    name: 'criar-icon',
    style: 'line',
    path:
      '<path d="M105.066,434.131H98.59V418.59h6.475a1.3,1.3,0,1,0,0-2.59H97.3a1.3,1.3,0,0,0-1.3,1.3v18.131a1.3,1.3,0,0,0,1.3,1.3h7.771a1.3,1.3,0,1,0,0-2.59Zm10.879-16.072-1.3-1.3a2.558,2.558,0,0,0-3.647,0l-9.054,9.066a2.546,2.546,0,0,0-.768,1.826v1.3a2.59,2.59,0,0,0,2.59,2.59h1.3a2.542,2.542,0,0,0,1.823-.764l9.054-9.066a2.559,2.559,0,0,0,0-3.652Zm-10.879,10.892h-1.3v-1.3l9.054-9.066,1.3,1.3Zm10.348,2.59h-1.3v-1.3a1.3,1.3,0,0,0-2.59,0v1.3h-1.3a1.3,1.3,0,0,0,0,2.59h1.3v1.3a1.3,1.3,0,0,0,2.59,0v-1.3h1.3a1.3,1.3,0,0,0,0-2.59Z" transform="translate(-96 -416)"/>',
    // viewBox="0 0 20.71 20.721"
  };
  const alterarIcon = {
    name: 'alterar-icon',
    style: 'line',
    path:
      '<path d="M83.42,436.727H65.3a1.3,1.3,0,0,1-1.3-1.3V417.3a1.3,1.3,0,0,1,1.3-1.3h7.763a1.3,1.3,0,1,1,0,2.591H66.589v15.545H82.126v-6.477a1.3,1.3,0,0,1,2.591,0v7.773a1.3,1.3,0,0,1-1.3,1.3Zm-2.591-18.136,1.3,1.3-9.059,9.068h-1.3v-1.3l9.059-9.068Zm0-2.591a2.576,2.576,0,0,0-1.83.759l-9.059,9.068a2.591,2.591,0,0,0-.76,1.832v1.3a2.591,2.591,0,0,0,2.591,2.591h1.3a2.591,2.591,0,0,0,1.83-.759l9.059-9.068a2.591,2.591,0,0,0,0-3.664l-1.3-1.3A2.577,2.577,0,0,0,80.832,416h0Z" transform="translate(-64.007 -416)"/>',
    // viewBox="0 0 20.71 20.727"
  };
  const importarIcon = {
    name: 'importar-icon',
    style: 'line',
    path:
      '<path d="M404.438,135.885a4.506,4.506,0,0,0-3.3-2.9A7.7,7.7,0,0,0,394.355,129a7.807,7.807,0,0,0-5.825,2.628,5.177,5.177,0,0,0-2.819,8.983,1.294,1.294,0,0,0,2.172-.957v-.009a1.252,1.252,0,0,0-.418-.942,2.589,2.589,0,0,1-.83-2.441,2.616,2.616,0,0,1,2.542-2.084,2.2,2.2,0,0,1,.647.091,5.171,5.171,0,0,1,9.578,1.372,1.747,1.747,0,0,1,.777-.168A1.941,1.941,0,0,1,402,136.766a1.8,1.8,0,0,1,.117.647,1.941,1.941,0,0,1-.73,1.514,1.376,1.376,0,0,0-.564,1.064A1.294,1.294,0,0,0,402.934,141a4.52,4.52,0,0,0,1.776-3.591,4.367,4.367,0,0,0-.272-1.529Zm-11.757,4.387.379-.379v5.935a1.294,1.294,0,0,0,2.589,0v-5.935l.379.379a1.294,1.294,0,1,0,1.83-1.83l-2.589-2.589a1.294,1.294,0,0,0-1.83,0l-2.589,2.589a1.294,1.294,0,1,0,1.83,1.83Z" transform="translate(-384 -129)"/>',
    // viewBox="0 0 20.71 18.121"
  };
  const importarcsvIcon = {
    name: 'importar2-icon',
    style: 'line',
    path:
      '<path d="M5.402.259l-.529 1.325h-.499L3.849.259h.466l.318.911.314-.911zm-1.674.905q0 .205-.191.332-.191.125-.522.125-.183 0-.332-.033-.149-.033-.24-.074v-.349h.04q.033.022.076.049.044.026.123.057.068.027.155.047.087.019.186.019.13 0 .192-.028.062-.028.062-.082 0-.048-.037-.069-.037-.022-.139-.043-.049-.011-.133-.022-.083-.013-.152-.031-.187-.047-.277-.146-.09-.1-.09-.249 0-.191.187-.317.188-.127.513-.127.154 0 .294.031.142.03.221.064v.335h-.038Q3.53.586 3.404.545 3.279.504 3.151.504q-.106 0-.18.029-.072.029-.072.08 0 .047.033.071.033.025.155.05.067.013.144.026.078.012.156.031.174.044.257.138.083.093.083.234zm-1.95.456q-.18 0-.329-.042-.148-.042-.258-.127-.109-.086-.169-.217-.06-.131-.06-.307 0-.185.064-.32.065-.135.18-.223.111-.084.257-.123.145-.039.302-.039.141 0 .259.03.119.03.221.077V.69h-.062Q2.156.669 2.12.641 2.084.613 2.032.586q-.05-.026-.11-.043-.06-.018-.139-.018-.176 0-.271.109-.094.107-.094.293 0 .191.097.29.098.099.276.099.083 0 .149-.018.067-.019.111-.044.042-.023.073-.049.032-.026.059-.051h.062v.362q-.104.047-.218.075-.112.029-.249.029zm3.472.321zm-2.08.106c-.578.001-1.128.217-1.512.592-.521.057-.957.373-1.114.808s-.008.91.383 1.215c.098.079.241.099.364.052s.201-.152.2-.268c0-.081-.039-.158-.108-.211a.54.54 0 0 1-.215-.55c.065-.272.34-.467.659-.469.057-.001.114.006.168.02.269-.424.813-.659 1.366-.591s.998.427 1.12.9c.063-.026.132-.039.202-.037.213.001.402.117.473.291.02.047.031.096.03.146 0 .133-.07.258-.19.341a.3.3 0 0 0-.146.24c-.001.113.074.216.191.264s.256.034.357-.037c.29-.193.461-.492.461-.809a.87.87 0 0 0-.07-.344c-.136-.332-.46-.579-.857-.653-.353-.556-1.028-.901-1.76-.898zm2.424.67zm-2.424.788c-.089 0-.175.031-.238.085l-.672.583c-.131.114-.131.298 0 .412s.344.114.475 0l.098-.087v1.337c.001.161.151.29.336.29s.335-.13.336-.29V4.498l.098.087c.131.114.344.114.475 0s.131-.299 0-.412l-.672-.583c-.063-.055-.148-.085-.237-.085z" transform="scale(3.6)"/>',
  };
  const exportarIcon = {
    name: 'exportar-icon',
    style: 'line',
    path:
      '<path d="M372.434,135.812a4.5,4.5,0,0,0-3.3-2.879,7.707,7.707,0,0,0-6.781-3.96,7.826,7.826,0,0,0-5.823,2.61,5.133,5.133,0,0,0-2.818,8.929,1.294,1.294,0,0,0,2.171-.951v-.008a1.241,1.241,0,0,0-.418-.937,2.556,2.556,0,0,1-.829-2.425,2.61,2.61,0,0,1,2.539-2.071,2.2,2.2,0,0,1,.647.089,5.176,5.176,0,0,1,9.576,1.364,1.746,1.746,0,0,1,.776-.168A1.941,1.941,0,0,1,370,136.692a1.773,1.773,0,0,1,.116.647,1.92,1.92,0,0,1-.73,1.5,1.365,1.365,0,0,0-.564,1.057,1.294,1.294,0,0,0,2.107,1.005,4.478,4.478,0,0,0,1.778-3.575A4.315,4.315,0,0,0,372.434,135.812Zm-8.411,6.394-.375.377v-5.9a1.294,1.294,0,0,0-2.588,0v5.9l-.379-.377a1.294,1.294,0,0,0-1.83,0,1.278,1.278,0,0,0,0,1.818l2.588,2.571a1.294,1.294,0,0,0,1.83,0l2.588-2.571a1.278,1.278,0,0,0,0-1.818,1.294,1.294,0,0,0-1.83,0Z" transform="translate(-351.996 -128.973)"/>',
    // viewBox="0 0 20.71 18.001"
  };
  const apiIcon = {
    name: 'api-icon',
    style: 'line',
    path:
      '<path d="M755.416,107.649a1.294,1.294,0,1,0,0-2.589h-1.294v-1.294h1.294a1.294,1.294,0,1,0,0-2.589h-1.294a2.589,2.589,0,0,0-2.589-2.589V97.294a1.294,1.294,0,1,0-2.589,0v1.294h-1.294V97.294a1.294,1.294,0,1,0-2.589,0v1.294h-1.294V97.294a1.294,1.294,0,1,0-2.589,0v1.294a2.589,2.589,0,0,0-2.589,2.589h-1.294a1.294,1.294,0,0,0,0,2.589h1.294v1.294h-1.294a1.294,1.294,0,0,0,0,2.589h1.294v1.294h-1.294a1.294,1.294,0,0,0,0,2.589h1.294a2.589,2.589,0,0,0,2.589,2.589v1.294a1.294,1.294,0,1,0,2.589,0v-1.294h1.294v1.294a1.294,1.294,0,1,0,2.589,0v-1.294h1.294v1.294a1.294,1.294,0,1,0,2.589,0v-1.294a2.589,2.589,0,0,0,2.589-2.589h1.294a1.294,1.294,0,1,0,0-2.589h-1.294v-1.294Zm-3.883,3.883H741.177V101.177h10.355Zm-7.766-2.589h5.177v-5.178h-5.177Z" transform="translate(-736 -96)"/>',
    // viewBox="0 0 20.71 20.71"
  };
  const analiseIcon = {
    name: 'analise-icon',
    style: 'line',
    path:
      '<path d="M682.377,197.894a3.894,3.894,0,1,0,3.885,3.894,3.894,3.894,0,0,0-3.885-3.894Zm0,5.192a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,682.377,203.085Zm10.264-1.781c-.122-.3-3-7.3-10.264-7.3s-10.143,7.009-10.262,7.3a1.3,1.3,0,0,0,0,.964c.115.3,2.994,7.306,10.262,7.306s10.146-7.009,10.264-7.306a1.3,1.3,0,0,0,0-.964Zm-10.264,5.674c-4.591,0-6.932-3.8-7.633-5.192.7-1.368,3.068-5.192,7.633-5.192,4.594,0,6.936,3.807,7.634,5.192C689.307,203.153,686.94,206.979,682.377,206.979Z" transform="translate(-672.021 -194)"/>',
    // viewBox="0 0 20.71 15.575"
  };
  const usersIcon = {
    name: 'users-icon',
    style: 'line',
    path:
      '<path d="M627.373,367.458h-1.292v-1.28a1.292,1.292,0,1,0-2.583,0v1.28h-1.292a1.28,1.28,0,1,0,0,2.56H623.5V371.3a1.292,1.292,0,1,0,2.583,0v-1.284h1.292a1.28,1.28,0,1,0,0-2.557Zm-9.041-2.561a5.158,5.158,0,0,0,4.482-2.624,5.1,5.1,0,0,0,4.51-5.772,106.757,106.757,0,0,1-13.561-4.361,5.178,5.178,0,0,0-4.408,4.3,5.1,5.1,0,0,0,1.486,4.435,6.358,6.358,0,0,0-2.841,5.3c0,.767.349,2.982,4.081,3.649.4,1.053,1.567,2.394,4.846,2.693a1.279,1.279,0,1,0,.2-2.549c-2.671-.232-2.671-1.232-2.671-1.232a3.853,3.853,0,0,1,3.875-3.838Zm3.875-10.242a2.583,2.583,0,0,1,2.583,2.56,2.552,2.552,0,0,1-1.326,2.221,5.1,5.1,0,0,0-2.833-4.223,2.566,2.566,0,0,1,1.577-.561Zm-3.875,2.56a2.567,2.567,0,1,1-1.823.744,2.561,2.561,0,0,1,1.823-.744Zm-3.875-2.56a2.569,2.569,0,0,1,1.577.558,5.1,5.1,0,0,0-2.834,4.223,2.552,2.552,0,0,1-1.326-2.221,2.583,2.583,0,0,1,2.583-2.562Zm-2.379,12.561c-1.472-.375-1.5-1.033-1.5-1.033a3.836,3.836,0,0,1,3.331-3.788,5.169,5.169,0,0,0,.832,1.059,6.415,6.415,0,0,0-2.667,3.76Z" transform="translate(-608 -352.139)"/>',
    // viewBox="0 0 20.71 20.451"
  };
  const adminIcon = {
    name: 'admin-icon',
    style: 'line',
    path:
      '<path d="M594.121,128h-9.061a2.6,2.6,0,0,0-2.589,2.589v1.294a1.294,1.294,0,1,0,2.589,0v-1.294h9.061v14.238h-1.294a1.294,1.294,0,1,0,0,2.589h1.294a2.6,2.6,0,0,0,2.589-2.589V130.589A2.6,2.6,0,0,0,594.121,128Zm-1.294,5.177a1.294,1.294,0,0,0-2.589,0,1.281,1.281,0,0,0,.065.388,1.291,1.291,0,0,0,2.524-.388Zm-5.177,1.294h-9.061A2.589,2.589,0,0,0,576,137.061v5.178a2.589,2.589,0,0,0,2.589,2.589h1.294v1.294h-1.424a1.165,1.165,0,0,0-1.165,1.165v.259a1.165,1.165,0,0,0,1.165,1.171h9.319a1.165,1.165,0,0,0,1.165-1.171v-.259a1.165,1.165,0,0,0-1.165-1.165h-1.424v-1.294h1.294a2.589,2.589,0,0,0,2.589-2.589v-5.178A2.589,2.589,0,0,0,587.649,134.472Zm-3.883,11.649h-1.294v-1.294h1.294Zm3.883-3.883h-9.061v-5.178h9.061Z" transform="translate(-576 -128)"/>',
    //   viewBox="0 0 20.71 20.717"
  };
  const zoomIcon = {
    name: 'zoom-icon',
    style: 'line',
    path:
      '<path d="M554.334,646.477h-1.293v-1.3a1.3,1.3,0,1,0-2.588,0v1.3H549.16a1.3,1.3,0,0,0,0,2.6h1.293v1.3a1.3,1.3,0,1,0,2.588,0v-1.3h1.293a1.3,1.3,0,0,0,0-2.6Zm9.971,12.087-6.243-6.272a7.756,7.756,0,1,0-1.824,1.839l6.243,6.272a1.3,1.3,0,1,0,1.825-1.839Zm-12.558-5.584a5.2,5.2,0,1,1,5.174-5.2A5.2,5.2,0,0,1,551.747,652.98Z" transform="translate(-543.979 -640.088)"/>',
    //  viewBox="0 0 20.71 20.697"
  };
  const removeIcon = {
    name: 'remove-icon',
    style: 'line',
    path:
      '<path d="M494.94,716.463l7.432-7.432a1.751,1.751,0,0,0-2.469-2.478l-7.432,7.432-7.432-7.432a1.751,1.751,0,0,0-2.469,2.478L490,716.463l-7.432,7.432a1.751,1.751,0,0,0,2.469,2.469l7.432-7.432,7.43,7.448a1.751,1.751,0,0,0,2.469-2.469Z" transform="translate(-482.115 -706.103)"/>',
    // viewBox="0 0 20.71 20.697"
  };
  const eliminarIcon = {
    name: 'eliminar-icon',
    style: 'line',
    path:
      '<path d="M5.178,20.71a2.589,2.589,0,0,1-2.589-2.588V6.472H1.294a1.294,1.294,0,1,1,0-2.588H6.472v-1.3A2.589,2.589,0,0,1,9.06,0H11.65a2.589,2.589,0,0,1,2.588,2.588v1.3h5.178a1.294,1.294,0,0,1,0,2.588H18.122v11.65a2.589,2.589,0,0,1-2.589,2.588Zm0-2.588H15.532V6.472H5.178ZM9.06,3.884H11.65v-1.3H9.06ZM9.734,14.9V11.438c0-.957-.1-1.726.716-1.732s.716.775.716,1.732V14.9c0,.956.121,1.7-.716,1.732h-.045C9.617,16.635,9.734,15.84,9.734,14.9Zm4.392,1.045c-.72-.018-.716-.581-.716-1.3V12.059c0-.715.1-1.306.716-1.3s.716.58.716,1.3v2.592c0,.705,0,1.3-.685,1.3Zm-8.066-1.3V12.059c0-.715-.01-1.3.716-1.3s.716.58.716,1.3v2.592c0,.715.037,1.288-.716,1.3H6.762C6.024,15.947,6.061,15.362,6.061,14.651Z"/>',
    // viewBox="0 0 20.71 20.71"
  };
  const limparIcon = {
    name: 'limpar-icon',
    style: 'line',
    path:
      '<path d="M50.225,450H38.576a2.582,2.582,0,0,0-2.071,1.043l-3.883,5.216a2.624,2.624,0,0,0,0,3.13l3.884,5.216a2.585,2.585,0,0,0,2.071,1.043H50.225a2.6,2.6,0,0,0,2.589-2.608V452.608A2.6,2.6,0,0,0,50.225,450Zm0,13.041H38.576l-3.884-5.216,3.884-5.216H50.225Zm-9.976-1.686a1.287,1.287,0,0,0,1.826,0l1.674-1.686,1.673,1.686a1.287,1.287,0,0,0,1.826,0,1.3,1.3,0,0,0,0-1.844l-1.664-1.686,1.673-1.686a1.3,1.3,0,0,0,0-1.844,1.287,1.287,0,0,0-1.826,0l-1.673,1.686-1.674-1.686a1.287,1.287,0,0,0-1.826,0,1.3,1.3,0,0,0,0,1.844l1.664,1.686-1.674,1.686a1.3,1.3,0,0,0,0,1.844Z" transform="translate(-32.104 -450)"/>',
    // viewBox="0 0 20.71 15.649"
  };
  const adicionarIcon = {
    name: 'adicionar-icon',
    style: 'line',
    path:
      '<path d="M553.061,715.649v7.766a1.294,1.294,0,0,0,2.589,0v-7.766h7.766a1.294,1.294,0,0,0,0-2.589h-7.766v-7.766a1.294,1.294,0,0,0-2.589,0v7.766h-7.766a1.294,1.294,0,0,0,0,2.589Z" transform="translate(-544 -704)"/>',
    //  viewBox="0 0 20.71 20.71"
  };
  const arrowBackIcon = {
    name: 'arrow-back-icon',
    style: 'line',
    path:
      '<path d="M425.511,722.707l14.477-14.655a2.341,2.341,0,0,0,0-3.294,2.283,2.283,0,0,0-3.255,0l-16.1,16.3a2.341,2.341,0,0,0,0,3.292l16.1,16.3a2.278,2.278,0,0,0,3.255,0,2.341,2.341,0,0,0,0-3.292Z" transform="translate(-419.955 -704.076)"/>',
    // viewBox="0 0 20.71 37.261"
  };
  const arrowUpIcon = {
    name: 'arrow-up-icon',
    style: 'line',
    path:
      '<path id="arrow_simple_shock" d="M425.511,722.707l14.477-14.655a2.341,2.341,0,0,0,0-3.294,2.283,2.283,0,0,0-3.255,0l-16.1,16.3a2.341,2.341,0,0,0,0,3.292l16.1,16.3a2.278,2.278,0,0,0,3.255,0,2.341,2.341,0,0,0,0-3.292Z" transform="translate(741.337 -419.955) rotate(90)" fill-rule="evenodd"/>',
    // viewBox="0 0 37.262 20.71"
  };
  const continuarIcon = {
    name: 'continuar-icon',
    style: 'line',
    path:
      '<path d="M425.511,722.707l14.477-14.655a2.341,2.341,0,0,0,0-3.294,2.283,2.283,0,0,0-3.255,0l-16.1,16.3a2.341,2.341,0,0,0,0,3.292l16.1,16.3a2.278,2.278,0,0,0,3.255,0,2.341,2.341,0,0,0,0-3.292Z" transform="translate(440.665 741.337) rotate(180)"/>',
    //  viewBox="0 0 20.71 37.261"
  };
  const descricaoIcon = {
    name: 'descricao-icon',
    style: 'line',
    path:
      '<path d="M82.751,449.479H80.873A2.959,2.959,0,0,0,78.314,448H72.4a2.959,2.959,0,0,0-2.559,1.479H67.959A2.968,2.968,0,0,0,65,452.438V468.71a2.967,2.967,0,0,0,2.959,2.959H82.751a2.967,2.967,0,0,0,2.959-2.959V452.438a2.967,2.967,0,0,0-2.959-2.958Zm-4.438,2.959H72.4v-1.479h5.917Zm4.438,16.272H67.959V452.438h1.479A2.967,2.967,0,0,0,72.4,455.4h5.917a2.967,2.967,0,0,0,2.959-2.959h1.479Zm-10.355-7.4h5.917a1.479,1.479,0,0,0,1.479-1.479h0a1.479,1.479,0,0,0-1.479-1.479H72.4a1.479,1.479,0,0,0-1.479,1.479h0A1.479,1.479,0,0,0,72.4,461.314Zm0,4.438h2.959a1.479,1.479,0,0,0,1.479-1.479h0a1.479,1.479,0,0,0-1.479-1.479H72.4a1.479,1.479,0,0,0-1.479,1.479h0A1.479,1.479,0,0,0,72.4,465.751Z" transform="translate(-65 -448)"/>',
    // viewBox="0 0 20.71 23.668"
  };
  const folderIcon = {
    name: 'folder-icon',
    style: 'line',
    path:
      '<path d="M370.121,451.589h-6.166l-.579-1.158a2.589,2.589,0,0,0-2.316-1.43h-6.472A2.589,2.589,0,0,0,352,451.589v12.944a2.589,2.589,0,0,0,2.589,2.589h15.533a2.589,2.589,0,0,0,2.589-2.589V454.177A2.589,2.589,0,0,0,370.121,451.589Zm0,12.944H354.589V451.589h6.472l1.294,2.589h1.294v6.692l2.589-2.589,2.589,2.589v-6.692h1.294Z" transform="translate(-352 -449)"/>',
    // viewBox="0 0 20.71 18.121"
  };
  const decisaoIcon = {
    name: 'decisao-icon',
    style: 'line',
    path:
      '<path d="M434.121,482.589v12.944h-9.829l-3.114,2.589v-2.589h-2.589V482.589h15.533m0-2.589H418.589A2.589,2.589,0,0,0,416,482.589v12.944a14.715,14.715,0,0,1,4.079,4.933,2.589,2.589,0,0,0,2.754-.353l2.393-1.991h8.895a2.589,2.589,0,0,0,2.589-2.589V482.589A2.589,2.589,0,0,0,434.121,480Zm-11.649,10.355h0a1.294,1.294,0,0,1-1.294-1.294h0a1.294,1.294,0,0,1,1.294-1.294h0a1.294,1.294,0,0,1,1.294,1.294h0A1.294,1.294,0,0,1,422.472,490.355Zm3.883,0h0a1.294,1.294,0,0,1-1.294-1.294h0a1.294,1.294,0,0,1,1.294-1.294h0a1.294,1.294,0,0,1,1.294,1.294h0A1.294,1.294,0,0,1,426.355,490.355Zm3.883,0h0a1.294,1.294,0,0,1-1.294-1.294h0a1.294,1.294,0,0,1,1.294-1.294h0a1.294,1.294,0,0,1,1.294,1.294h0A1.294,1.294,0,0,1,430.238,490.355Z" transform="translate(-416 -480)"/>',
    // viewBox="0 0 20.71 20.71"
  };
  const validarIcon = {
    name: 'validar-icon',
    style: 'line',
    path:
      '<path d="M18.5,20.316l-6.242-6.272a7.668,7.668,0,0,1-4.491,1.45A7.746,7.746,0,1,1,14.083,12.2l6.243,6.271a1.3,1.3,0,0,1,0,1.84,1.29,1.29,0,0,1-1.83,0ZM4.051,4.021a5.2,5.2,0,1,0,8.133.964L7.558,11.179a.528.528,0,0,1-.388.212l-.038,0a.527.527,0,0,1-.378-.157L4.092,8.563a.54.54,0,0,1,0-.755.531.531,0,0,1,.755,0l2.228,2.238L11.5,4.12l.012-.015a5.2,5.2,0,0,0-7.459-.084Z"/>',
    // viewBox="0 0 20.709 20.696"
  };
  const guardarIcon = {
    name: 'guardar-icon',
    style: 'line',
    path:
      '<path d="M403.951,100.642l-3.883-3.883a2.589,2.589,0,0,0-1.83-.758H386.589A2.589,2.589,0,0,0,384,98.589v15.532a2.589,2.589,0,0,0,2.589,2.589h15.533a2.589,2.589,0,0,0,2.589-2.589V102.472a2.589,2.589,0,0,0-.758-1.83Zm-10.891-2.053v2.589h-2.589V98.589Zm-2.589,15.532v-3.883h7.766v3.883Zm11.649,0h-1.294v-3.883a2.589,2.589,0,0,0-2.589-2.589h-7.766a2.589,2.589,0,0,0-2.589,2.589v3.883h-1.294V98.589h1.294v2.589a2.589,2.589,0,0,0,2.589,2.589h3.883a2.589,2.589,0,0,0,2.589-2.589V98.589h1.294l3.883,3.883Z" transform="translate(-384 -96)"/>',
    // viewBox="0 0 20.71 20.71"
  };
  const relogioIcon = {
    name: 'relogio-icon',
    style: 'line',
    path:
      '<path d="M74.355,256A10.355,10.355,0,1,0,84.71,266.355,10.355,10.355,0,0,0,74.355,256Zm0,18.121a7.766,7.766,0,1,1,7.766-7.766A7.766,7.766,0,0,1,74.355,274.121Zm3.883-9.061H75.649v-2.589a1.294,1.294,0,1,0-2.589,0v3.883a1.294,1.294,0,0,0,1.294,1.294h3.883a1.294,1.294,0,1,0,0-2.589Z" transform="translate(-64 -256)"/>',
    // viewBox="0 0 20.71 20.71"
  };
  const passwordIcon = {
    name: 'password-icon',
    style: 'line',
    path:
      '<path d="M338.743,135.393h-1.479v-1.479a5.914,5.914,0,1,0-11.829,0v1.479h-1.479A2.957,2.957,0,0,0,321,138.35V148.7a2.957,2.957,0,0,0,2.957,2.957h14.786A2.957,2.957,0,0,0,341.7,148.7V138.35A2.957,2.957,0,0,0,338.743,135.393Zm-10.35-1.479a2.957,2.957,0,1,1,5.914,0v1.479h-5.914Zm10.35,14.786H323.957V138.35h14.786Zm-8.846-4.563c0,.043-.025.081-.025.127v2.957h2.957v-2.957a1.129,1.129,0,0,0-.027-.127,2.218,2.218,0,0,0-.713-4.309h-1.479a2.218,2.218,0,0,0-.714,4.309Z" transform="translate(-321 -128)"/>',
    // viewBox="0 0 20.7 23.657"
  };
  const emailIcon = {
    name: 'email-icon',
    style: 'line',
    path:
      '<path d="M116.516,521.689a9.709,9.709,0,0,0-2.053-5.585,10.551,10.551,0,0,0-10.42-3.8,9.971,9.971,0,0,0,2.133,19.779,10.514,10.514,0,0,0,4.536-1.016,1.217,1.217,0,0,0,.3-1.985h0a1.292,1.292,0,0,0-1.432-.257,8.02,8.02,0,0,1-4.561.676,7.484,7.484,0,0,1,1.161-14.91,7.869,7.869,0,0,1,4.662,1.512,7.442,7.442,0,0,1,2.071,2.248,7.219,7.219,0,0,1,1.037,3.735,1.3,1.3,0,0,1-2.591,0,5,5,0,0,0-3.094-4.559,5.317,5.317,0,0,0-3.628-.221,5.016,5.016,0,1,0,3.628,9.339,4.877,4.877,0,0,0,2.053-1.573,3.953,3.953,0,0,0,2.332.761,1.849,1.849,0,0,0,.257-.011,3.85,3.85,0,0,0,3.614-3.336c0-.128.013-.263.013-.4S116.516,521.813,116.516,521.689Zm-9.828,2.847a2.377,2.377,0,0,1-.513.05,2.5,2.5,0,1,1,0-5,2.365,2.365,0,0,1,.513.05,2.483,2.483,0,0,1,0,4.9Z" transform="translate(-95.819 -512.084)"/>',
    // viewBox="0 0 20.71 20.005"
  };
  const ccIcon = {
    name: 'cc-icon',
    style: 'line',
    path:
      '<path d="M50.121,354H34.589A2.589,2.589,0,0,0,32,356.589v10.355a2.589,2.589,0,0,0,2.589,2.589H50.121a2.589,2.589,0,0,0,2.589-2.589V356.589A2.589,2.589,0,0,0,50.121,354Zm0,12.944H34.589V356.589H50.121Zm-7.766-6.472a2.589,2.589,0,1,0-2.589,2.589A2.589,2.589,0,0,0,42.355,360.472Zm-2.589,2.589a3.842,3.842,0,0,0-3.65,2.589h7.3A3.842,3.842,0,0,0,39.766,363.061Zm7.766-5.177H44.944a1.294,1.294,0,0,0-1.294,1.294h0a1.294,1.294,0,0,0,1.294,1.294h2.589a1.294,1.294,0,0,0,1.294-1.294h0A1.294,1.294,0,0,0,47.533,357.883Zm0,3.883H46.238a1.294,1.294,0,0,0-1.294,1.294h0a1.294,1.294,0,0,0,1.294,1.294h1.294a1.294,1.294,0,0,0,1.294-1.294h0A1.294,1.294,0,0,0,47.533,361.766Z" transform="translate(-32 -354)"/>',
    // viewBox="0 0 20.71 15.532"
  };
  const entidadeIcon = {
    name: 'entidade-icon',
    style: 'line',
    path:
      '<path d="M180.455,518.74l-2.589-5.276A2.586,2.586,0,0,0,175.548,512H165.191a2.584,2.584,0,0,0-2.316,1.464l-2.589,5.276a2.663,2.663,0,0,0,.492,3.034l.375.382a1.562,1.562,0,0,0,.156.145v8.165A2.621,2.621,0,0,0,163.9,533.1h12.946a2.621,2.621,0,0,0,2.588-2.638V522.3a1.434,1.434,0,0,0,.156-.145l.376-.383A2.663,2.663,0,0,0,180.455,518.74Zm-15.264-4.1h10.357l1.994,4.063.595,1.214-.376.382a3.084,3.084,0,0,1-4.427,0l-.376-.382-.375.382a3.085,3.085,0,0,1-4.428,0l-.379-.382-.375.382a3.085,3.085,0,0,1-4.428,0l-.376-.382.6-1.214Zm11.652,15.829h-3.885V525.19h-5.181v5.276h-3.883v-6.74a5.993,5.993,0,0,0,1.294.145,5.612,5.612,0,0,0,2.589-.633,5.611,5.611,0,0,0,5.179,0,5.617,5.617,0,0,0,2.589.633,6,6,0,0,0,1.3-.145v6.74Z" transform="translate(-160.015 -512)"/>',
    // viewBox="0 0 20.711 21.105"
  };
  const tipologiaEntIcon = {
    name: 'tipologia-ent-icon',
    style: 'line',
    path:
      '<path d="M2.647,22.48a1.787,1.787,0,0,1-1.765-1.8V15.115a.915.915,0,0,1-.106-.1L.52,14.756a1.816,1.816,0,0,1-.336-2.068L1.95,9.09a1.761,1.761,0,0,1,1.579-1H6.323V2.81c0-1.552.806-2.81,1.8-2.81h10.79c.994,0,1.8,1.258,1.8,2.81V19.67c0,1.552-.806,2.81-1.8,2.81H11.473Zm0-1.8H5.292v-3.6h2.83V16.034a3.83,3.83,0,0,1-2.83-.281,3.822,3.822,0,0,1-1.765.431,4.039,4.039,0,0,1-.882-.1ZM18.912,19.67V2.81H8.122V8.092h2.468a1.763,1.763,0,0,1,1.58,1l1.765,3.6a1.816,1.816,0,0,1-.335,2.068l-.257.261a.994.994,0,0,1-.106.1V19.67Zm-7.44,0V16.087a4.061,4.061,0,0,1-.884.1,3.827,3.827,0,0,1-1.765-.431,3.823,3.823,0,0,1-.7.281v1.051h.7V19.67Zm-2.39-5.921a2.1,2.1,0,0,0,3.018,0l.257-.261-.406-.827-1.36-2.77H8.122V14.1a2.1,2.1,0,0,0,.447-.35l.256-.261Zm-3.531,0a2.1,2.1,0,0,0,2.571.35V9.89H3.529L2.168,12.66l-.406.827.256.261a2.105,2.105,0,0,0,3.02,0l.255-.261Zm9.765,3.336v-1.8a.9.9,0,1,1,1.8,0v1.8a.9.9,0,1,1-1.8,0Zm0-5.4V9.89a.9.9,0,1,1,1.8,0v1.8a.9.9,0,1,1-1.8,0Zm0-5.4V4.5a.9.9,0,1,1,1.8,0v1.8a.9.9,0,0,1-1.8,0Zm-5.4,0V4.5a.9.9,0,1,1,1.8,0v1.8a.9.9,0,0,1-1.8,0Z"/>',
    // viewBox="0 0 20.711 22.48"
  };
  const cmdIcon = {
    name: 'cmd-icon',
    style: 'line',
    path:
      '<path d="M8666.75,3954.447a11.477,11.477,0,1,1,0-22.947h.168v4.5h-.152a6.974,6.974,0,1,0,0,13.944h.148v4.5h-.011v.007Zm3.277-6.987v-1.968l.023.216.109-.011h2.391v1.938a1.167,1.167,0,0,1-.912.919.949.949,0,0,1-.327.054A1.254,1.254,0,0,1,8670.027,3947.46Zm3.159-3.9V3941.6l.028-.224.028,0h2.467v1.931s-.074,1.017-.747,1.3a1.024,1.024,0,0,1-.494.113A1.258,1.258,0,0,1,8673.187,3943.563Zm-3.159,0V3941.6l.023-.224.109,0h2.391v1.931s-.078,1.021-.751,1.3a1.033,1.033,0,0,1-.488.113A1.261,1.261,0,0,1,8670.027,3943.563Zm-3.153,0V3941.6l.028-.224.031,0h2.464v1.931s-.073,1.017-.746,1.3a1.022,1.022,0,0,1-.49.113A1.26,1.26,0,0,1,8666.874,3943.563Zm3.153-3.884v-1.964l.023-.039.109-.013h2.391v1.942a1.376,1.376,0,0,1-.912,1.171,1.063,1.063,0,0,1-.327.05A1.254,1.254,0,0,1,8670.027,3939.678Z" transform="translate(-8654.999 -3931.5)"/>',
    // viewBox="0 0 20.711 22.947"
  };
  const recuperarPasswordIcon = {
    name: 'recuperar-password-icon',
    style: 'line',
    path:
      '<path d="M11.992,21.648A8.9,8.9,0,0,1,3.54,14.37l-.986,1.642A1.115,1.115,0,0,1,.642,14.863l2.3-3.823a1.122,1.122,0,0,1,1.53-.381l2.07,1.522c.528.317,1.006,1.119.582,1.713s-1.359.183-1.372.14A6.708,6.708,0,1,0,7.122,8.546,1.117,1.117,0,0,1,5.386,7.14,8.9,8.9,0,1,1,12.3,21.653C12.195,21.653,12.093,21.651,11.992,21.648ZM9,17.632a1.315,1.315,0,0,1-1.315-1.315v-4.6A1.315,1.315,0,0,1,9,10.4h.657V9.746a2.629,2.629,0,1,1,5.258,0V10.4h.657a1.314,1.314,0,0,1,1.314,1.314v4.6a1.314,1.314,0,0,1-1.314,1.315Zm0-1.315H15.57v-4.6H9ZM10.97,9.746V10.4H13.6V9.746a1.315,1.315,0,0,0-2.629,0Zm.657,5.914V14.347c0-.021.011-.038.011-.056a.986.986,0,0,1,.318-1.915h.657a.986.986,0,0,1,.317,1.915c0,.019.009.037.012.056v1.314Z" transform="translate(-0.482 -3.853)"/>',
    // viewBox="0 0 20.71 17.8"
  };
  const renovarApiIcon = {
    name: 'renovar-api-icon',
    style: 'line',
    path:
      '<path d="M0,19.11v-5.1a1.283,1.283,0,0,1,1.294-1.273H3.9c.106.007,1.859.086,1.726,1.468-.118,1.228-.907,1.155-1.041,1.1a7.638,7.638,0,0,0,4.1,2.35,7.766,7.766,0,0,0,9.351-6.367,1.292,1.292,0,0,1,2.559.356,10.39,10.39,0,0,1-18,5.267v2.2A1.294,1.294,0,0,1,0,19.11ZM11.186,14.4v-.618h-.619V14.4a.618.618,0,0,1-1.237,0v-.618H8.711V14.4a.618.618,0,0,1-1.237,0v-.618a1.238,1.238,0,0,1-1.238-1.238H5.619a.619.619,0,0,1,0-1.238h.618v-.618H5.619a.619.619,0,0,1,0-1.238h.618V8.836H5.619a.618.618,0,1,1,0-1.237h.618A1.237,1.237,0,0,1,7.475,6.362V5.744a.618.618,0,1,1,1.237,0v.618H9.33V5.744a.618.618,0,1,1,1.237,0v.618h.619V5.744a.618.618,0,1,1,1.237,0v.618A1.237,1.237,0,0,1,13.66,7.6h.618a.618.618,0,0,1,0,1.237H13.66v.618h.618a.619.619,0,0,1,0,1.238H13.66v.618h.618a.619.619,0,0,1,0,1.238H13.66a1.238,1.238,0,0,1-1.238,1.238V14.4a.618.618,0,0,1-1.237,0ZM7.475,12.548h4.948V7.6H7.475ZM8.711,11.31V8.836h2.475V11.31ZM1.218,10.166A1.294,1.294,0,0,1,.106,8.756,10.323,10.323,0,0,1,8.927.1,10.435,10.435,0,0,1,18.11,3.47V1.28a1.3,1.3,0,0,1,2.6,0V6.368a1.273,1.273,0,0,1-.427.942,1.3,1.3,0,0,1-.868.33H16.889c-.464.015-1.7-.211-1.726-1.273a1.226,1.226,0,0,1,.957-1.273A7.788,7.788,0,0,0,2.669,9.108a1.295,1.295,0,0,1-1.452,1.058Z"/>',
    // viewBox="0 0 20.71 20.404"
  };
  const legislacaoIcon = {
    name: 'legislacao-icon',
    style: 'line',
    path:
      '<path d="M690.751,416H675.959A2.967,2.967,0,0,0,673,418.959v16.272a4.451,4.451,0,0,0,4.438,4.438h13.314a2.967,2.967,0,0,0,2.958-2.959V418.959A2.967,2.967,0,0,0,690.751,416Zm0,20.71H677.438a1.479,1.479,0,0,1,0-2.959h13.314Zm0-5.917H677.438a4.1,4.1,0,0,0-1.479.266v-12.1h7.4v7.4l2.959-1.479,2.958,1.479v-7.4h1.479Z" transform="translate(-673 -416)"/>',
    // viewBox="0 0 20.71 23.668"
  };
  const descriptionIcon = {
    name: 'description-icon',
    style: 'line',
    path:
      '<path id="paper_1_text" d="M366.314,433.751H360.4a1.479,1.479,0,0,1-1.479-1.479h0a1.479,1.479,0,0,1,1.479-1.479h5.917a1.479,1.479,0,0,1,1.479,1.479h0A1.479,1.479,0,0,1,366.314,433.751Zm0-4.438H360.4a1.479,1.479,0,0,1-1.479-1.479h0a1.479,1.479,0,0,1,1.479-1.479h5.917a1.479,1.479,0,0,1,1.479,1.479h0A1.479,1.479,0,0,1,366.314,429.314Zm0-13.314H355.959A2.967,2.967,0,0,0,353,418.959V436.71a2.968,2.968,0,0,0,2.959,2.959h14.793a2.967,2.967,0,0,0,2.959-2.959V423.4A7.4,7.4,0,0,0,366.314,416Zm4.438,20.71H355.959V418.959h7.4v7.4h7.4ZM366.314,423.4v-4.438a4.451,4.451,0,0,1,4.438,4.438Z" transform="translate(-353 -416)"/>',
    // viewBox="0 0 20.71 23.668"
  };
  const acceptIcon = {
    name: 'accept-icon',
    style: 'line',
    path:
      '<path d="M583.785,723.3a1.289,1.289,0,0,1-.918-.38l-6.473-6.5a1.311,1.311,0,0,1,0-1.836,1.29,1.29,0,0,1,1.836,0l5.418,5.441L594.4,705.616a1.291,1.291,0,0,1,1.812-.262,1.311,1.311,0,0,1,.262,1.821l-11.653,15.6a1.289,1.289,0,0,1-.944.517C583.847,723.3,583.815,723.3,583.785,723.3Z" transform="translate(-576.019 -705.094)"/>',
    // viewBox="0 0 20.71 18.204"
  };
  const closeAllIcon = {
    name: 'close-all-icon',
    style: 'line',
    path:
      '<path d="M308.373,722.679l-4.263-4.287h2.053a1.3,1.3,0,0,0,0-2.6h-5.178a1.281,1.281,0,0,0-.451.092,1.177,1.177,0,0,0-.123.059,1.259,1.259,0,0,0-.27.183c-.034.03-.068.056-.1.089a1.317,1.317,0,0,0-.232.347c0,.01-.013.02-.018.03h0a1.29,1.29,0,0,0-.089.451c0,.016-.009.03-.009.047V722.3a1.3,1.3,0,1,0,2.59,0v-2.064l4.262,4.286a1.29,1.29,0,0,0,1.832,0A1.309,1.309,0,0,0,308.373,722.679Zm-12.562-17.3a1.3,1.3,0,0,0-1.294,1.309v2.064l-4.263-4.286a1.289,1.289,0,0,0-1.832,0,1.309,1.309,0,0,0,0,1.841l4.262,4.286H290.63a1.3,1.3,0,0,0,0,2.6h5.178a1.284,1.284,0,0,0,.453-.092c.043-.017.081-.038.123-.059a1.253,1.253,0,0,0,.27-.183,1.29,1.29,0,0,0,.1-.089,1.256,1.256,0,0,0,.232-.345c0-.012.014-.02.02-.031h0a1.29,1.29,0,0,0,.089-.451c0-.016.009-.03.009-.047v-5.206a1.3,1.3,0,0,0-1.294-1.313Z" transform="translate(-288.042 -704.081)"/>',
    // viewBox="0 0 20.71 20.818"
  };
  const expandAllIcon = {
    name: 'expand-all-icon',
    style: 'line',
    path:
      '<path d="M262.851,716.029l-4.262,4.262v-2.053a1.294,1.294,0,0,0-2.589,0v5.177a1.3,1.3,0,0,0,.091.452,1.354,1.354,0,0,0,.058.123,1.277,1.277,0,0,0,.613.6c.012,0,.021.014.034.019h0a1.3,1.3,0,0,0,.5.1h5.177a1.294,1.294,0,0,0,0-2.589h-2.053l4.262-4.262a1.294,1.294,0,1,0-1.83-1.83Zm13.768-11.186a1.376,1.376,0,0,0-.06-.129,1.3,1.3,0,0,0-.181-.269,1.129,1.129,0,0,0-.43-.327c-.013,0-.022-.016-.035-.022h0a1.273,1.273,0,0,0-.45-.089c-.017,0-.03-.009-.045-.009h-5.177a1.294,1.294,0,0,0,0,2.589h2.053l-4.262,4.262a1.294,1.294,0,1,0,1.83,1.83l4.26-4.259v2.053a1.294,1.294,0,1,0,2.589,0V705.3A1.3,1.3,0,0,0,276.619,704.843Z" transform="translate(724.711 -256) rotate(90)"/>',
    // viewBox="0 0 20.714 20.71"
  };
  const lookIcon = {
    name: 'look-icon',
    style: 'line',
    path:
      '<path d="M714.377,197.894a3.894,3.894,0,1,0,3.883,3.894,3.894,3.894,0,0,0-3.883-3.894Zm10.262,3.411c-.121-.3-2.994-7.3-10.262-7.3s-10.144,7.008-10.263,7.3a1.3,1.3,0,0,0,0,.964c.117.3,3,7.306,10.263,7.306s10.144-7.008,10.262-7.306a1.3,1.3,0,0,0,0-.964Zm-10.262,5.674c-4.592,0-6.933-3.8-7.634-5.191.7-1.368,3.068-5.191,7.634-5.191,4.593,0,6.934,3.807,7.634,5.191C721.307,203.152,718.94,206.978,714.377,206.978Z" transform="translate(-704.021 -194)"/>',
    // viewBox="0 0 20.71 15.574"
  };
  const pdfIcon = {
    name: 'pdf-icon',
    style: 'line',
    path:
      '<path d="M2.958,23.668A2.967,2.967,0,0,1,0,20.71V2.958A2.967,2.967,0,0,1,2.958,0H13.314a7.4,7.4,0,0,1,7.4,7.4V20.71a2.967,2.967,0,0,1-2.958,2.958Zm0-2.958H17.752V9.235H10.715V2.958H2.958v8.058H15.943c.512,0,1.108.93,1.108,3.965.081,1.084.063,3.686-1.108,3.893l-12.139,0c-.191.006-.449.021-.708.021l-.137,0ZM8.807,13.1v4a7.1,7.1,0,0,0,1,.063,2.731,2.731,0,0,0,1.8-.526,2.11,2.11,0,0,0,.649-1.649,1.834,1.834,0,0,0-.661-1.535,2.441,2.441,0,0,0-1.6-.435A7.64,7.64,0,0,0,8.807,13.1Zm4.038,4.018h.885V15.476h1.4v-.748h-1.4V13.8h1.5v-.755H12.845ZM5.439,13.1v4.018h.876V15.656a2.176,2.176,0,0,0,.3.018,1.759,1.759,0,0,0,1.27-.43,1.318,1.318,0,0,0,.358-.965,1.215,1.215,0,0,0-.416-.942,1.817,1.817,0,0,0-1.194-.326A7,7,0,0,0,5.439,13.1Zm7.875-6.419h4.438c-.007-2.448-1.99-3.711-4.438-3.718ZM10,16.437a1.378,1.378,0,0,1-.307-.021V13.753a1.788,1.788,0,0,1,.4-.036,1.145,1.145,0,0,1,1.228,1.292c0,.967-.517,1.427-1.31,1.427ZM6.312,14.944v-1.2a1.474,1.474,0,0,1,.365-.036c.441,0,.689.221.689.6,0,.417-.288.663-.759.663A1.319,1.319,0,0,1,6.312,14.944Z"/>',
    // viewBox="0 0 20.71 23.668"
  };
  const participacaoIcon = {
    name: 'participacao-icon',
    style: 'line',
    path:
      '<path d="M753.719,715.79a1.3,1.3,0,1,0-1.83,1.84l.381.381h-1.517l-9.971-10.027a1.286,1.286,0,0,0-.911-.381h-2.588a1.3,1.3,0,0,0,0,2.6h2.052l9.971,10.027a1.3,1.3,0,0,0,.911.381h2.053l-.38.381a1.3,1.3,0,1,0,1.831,1.84l2.586-2.6a1.3,1.3,0,0,0,0-1.84Zm-13.256,1.08-1.128,1.141h-2.057a1.3,1.3,0,0,0,0,2.6h2.588a1.333,1.333,0,0,0,.918-.377l1.516-1.523a1.31,1.31,0,0,0,0-1.845,1.293,1.293,0,0,0-1.837,0Zm9.156-5.524,1.127-1.141h1.523l-.39.377a1.3,1.3,0,1,0,1.838,1.847l2.586-2.6a1.314,1.314,0,0,0,0-1.847l-2.586-2.6a1.3,1.3,0,1,0-1.838,1.847l.39.377h-2.057a1.333,1.333,0,0,0-.92.377l-1.514,1.523a1.31,1.31,0,0,0,0,1.845,1.293,1.293,0,0,0,1.841,0Z" transform="translate(-735.977 -704.999)"/>',
    // viewBox="0 0 20.71 18.216"
  };
  const participadoIcon = {
    name: 'participado-icon',
    style: 'line',
    path:
      '<path d="M15.747,17.3a1.286,1.286,0,0,1,0-1.82l.376-.378H14.091a1.287,1.287,0,0,1-.9-.378L3.323,4.8H1.293a1.287,1.287,0,1,1,0-2.575H3.854a1.274,1.274,0,0,1,.9.377l9.866,9.922h1.5l-.378-.377a1.284,1.284,0,1,1,1.812-1.82l2.561,2.575a1.286,1.286,0,0,1,0,1.82L17.559,17.3a1.277,1.277,0,0,1-1.812,0ZM1.288,15.1a1.287,1.287,0,1,1,0-2.575H3.323L4.44,11.4a1.287,1.287,0,0,1,1.818,1.823l-1.5,1.508a1.319,1.319,0,0,1-.909.373Zm8.732-9.135a.75.75,0,0,1,.124-1.059c.68-.541,1.109-.289,1.229-.167l2.672,2.683L19.226.208a.922.922,0,0,1,1.168,0,.8.8,0,0,1,.158,1.117c-5.6,8.182-5.538,8.13-6.249,8.176-.087.006-.163.012-.236.012C13.537,9.513,13.151,9.188,10.019,5.968Z"/>',
    // viewBox="0 0 20.71 17.678"
  };
  const editadoIcon = {
    name: 'editado-icon',
    style: 'line',
    path:
      '<path d="M1.3,20.727a1.3,1.3,0,0,1-1.3-1.3V1.3A1.3,1.3,0,0,1,1.3,0H9.059a1.3,1.3,0,0,1,0,2.591H2.582V18.136H18.12V11.659a1.3,1.3,0,1,1,2.59,0v7.772a1.3,1.3,0,0,1-1.3,1.3Zm8.268-6.542L4.412,9.01a1.043,1.043,0,0,1,0-1.461,1.028,1.028,0,0,1,1.462,0l4.312,4.331L18.742.415A1.028,1.028,0,0,1,20.184.206a1.044,1.044,0,0,1,.209,1.449L11.119,14.073a1.026,1.026,0,0,1-.751.411l-.074,0h0A1.024,1.024,0,0,1,9.564,14.185Z"/>',
    // viewBox="0 0 20.71 20.727"
  };
  const tsIcon = {
    name: 'ts-icon',
    style: 'line',
    path:
      '<path d="M4.438,23.668A2.958,2.958,0,0,1,1.48,20.71V19.23a1.479,1.479,0,1,1,0-2.958V13.314a1.479,1.479,0,1,1,0-2.958V7.4a1.479,1.479,0,1,1,0-2.958V2.958A2.959,2.959,0,0,1,4.438,0H17.752A2.959,2.959,0,0,1,20.71,2.958V20.71a2.958,2.958,0,0,1-2.958,2.958Zm0-19.23a1.479,1.479,0,1,1,0,2.958v2.959a1.479,1.479,0,1,1,0,2.958v2.958a1.479,1.479,0,1,1,0,2.958v1.48H17.752V2.958H4.438ZM11.112,12.5l.519-1.167a3.539,3.539,0,0,0,1.849.552c.656,0,.912-.184.912-.456,0-.887-3.184-.239-3.184-2.319,0-1,.816-1.816,2.48-1.816a4.192,4.192,0,0,1,2.032.488l-.489,1.176A3.306,3.306,0,0,0,13.68,8.52c-.664,0-.9.224-.9.5,0,.856,3.175.216,3.175,2.28,0,.976-.815,1.808-2.479,1.808A4.408,4.408,0,0,1,11.112,12.5Zm-3.48.5V8.656H5.912V7.4h5.016V8.656H9.216V13Z"/>',
    // viewBox="0 0 20.71 23.668"
  };
  const pedidoAprTecnIcon = {
    name: 'pedido-apr-tecn-icon',
    style: 'line',
    path:
      '<path d="M16.812,20.3l-3.8-2.552H2.589A2.792,2.792,0,0,1,0,14.792V2.958A2.792,2.792,0,0,1,2.589,0H18.123a2.792,2.792,0,0,1,2.588,2.958V17.751a3.058,3.058,0,0,1-1.3,2.569,2.313,2.313,0,0,1-2.6-.017ZM2.589,14.792H13.714l4.409,2.958V2.958H2.589Zm11.928-.329a32.881,32.881,0,0,1-2.81-2.851,3.764,3.764,0,0,1-2.123.7,4,4,0,0,1-3.8-2.739,3.881,3.881,0,0,1,1.775-4.25,4.428,4.428,0,0,1,2.7-.538,3.64,3.64,0,0,1,2.052,1.134c1.075,1.229,1.526,3.487.586,4.824,0,0,2.412,2.486,2.587,2.812.151.279-.294.966-.75.966A.441.441,0,0,1,14.517,14.463ZM7.884,6.839a2.378,2.378,0,1,0,1.688-.7A2.377,2.377,0,0,0,7.884,6.839Z"/>',
    // viewBox="0 0 20.712 20.71"
  };
  const pedidoAprovadoIcon = {
    name: 'pedido-aprovado-icon',
    style: 'line',
    path:
      '<path d="M16.812,20.3l-3.8-2.552H2.589A2.792,2.792,0,0,1,0,14.792V2.958A2.792,2.792,0,0,1,2.589,0H18.123a2.792,2.792,0,0,1,2.588,2.958V17.751a3.058,3.058,0,0,1-1.3,2.569,2.313,2.313,0,0,1-2.6-.017ZM2.589,14.792H13.714l4.409,2.958V2.958H2.589Zm6-1.669L5.18,10.084A.965.965,0,0,1,6.1,8.419l2.855,2.544,5.279-6.318a1.018,1.018,0,0,1,1.523,1.118l-6.141,7.3a.6.6,0,0,1-.5.267A.9.9,0,0,1,8.59,13.124Z"/>',
    // viewBox="0 0 20.712 20.71"
  };
  const pedidoDespachoIcon = {
    name: 'pedido-despacho-icon',
    style: 'line',
    path:
      '<path d="M16.812,20.3l-3.8-2.552H2.589A2.792,2.792,0,0,1,0,14.792V2.958A2.792,2.792,0,0,1,2.589,0H18.123a2.792,2.792,0,0,1,2.588,2.958V17.751a3.058,3.058,0,0,1-1.3,2.569,2.313,2.313,0,0,1-2.6-.017ZM2.589,14.792H13.714l4.409,2.958V2.958H2.589Zm7.7-2.188a.984.984,0,0,1,0-1.4l1.29-1.28H5.991a.99.99,0,0,1,0-1.98h5.59L10.293,6.659a.981.981,0,0,1,0-1.4,1.006,1.006,0,0,1,1.415,0l3,2.971a.952.952,0,0,1,.2.294c.014.034.023.067.034.1a.993.993,0,0,1,.05.246v.1a.971.971,0,0,1-.059.316c0,.009-.005.017-.007.026a.937.937,0,0,1-.2.291c-.008.009-.011.022-.02.031l-3,2.971a1.01,1.01,0,0,1-1.416,0Z"/>',
    // viewBox="0 0 20.712 20.71"
  };
  const pedidoDevolvidoIcon = {
    name: 'pedido-devolvido-icon',
    style: 'line',
    path:
      '<path id="Union_1" data-name="Union 1" d="M16.812,20.3l-3.8-2.552H2.589A2.792,2.792,0,0,1,0,14.792V2.958A2.792,2.792,0,0,1,2.589,0H18.123a2.792,2.792,0,0,1,2.588,2.958V17.751a3.058,3.058,0,0,1-1.3,2.569,2.313,2.313,0,0,1-2.6-.017ZM2.589,14.792H13.714l4.409,2.958V2.958H2.589Zm4.356-2.01L4.809,10.53c-.006-.007-.006-.015-.013-.023a.653.653,0,0,1-.136-.225.03.03,0,0,1-.007-.023.664.664,0,0,1-.042-.233V9.952a.836.836,0,0,1,.035-.188.235.235,0,0,1,.029-.075.736.736,0,0,1,.135-.226L6.945,7.212a.688.688,0,0,1,1.011,0,.783.783,0,0,1,0,1.067l-.926.968H13.51a1.128,1.128,0,0,0,0-2.253h-3.2a.752.752,0,0,1,0-1.5h3.2A2.563,2.563,0,0,1,16,8.12a2.563,2.563,0,0,1-2.492,2.628H7.031l.926.968a.788.788,0,0,1,0,1.067.7.7,0,0,1-1.012,0Z"/>',
    // viewBox="0 0 20.712 20.71"
  };
  const pedidoEmValidacaoIcon = {
    name: 'pedido-em-validacao-icon',
    style: 'line',
    path:
      '<path d="M334.238,490.355a1.294,1.294,0,1,1,1.294-1.294A1.294,1.294,0,0,1,334.238,490.355Zm-3.883,0a1.294,1.294,0,1,1,1.294-1.294A1.294,1.294,0,0,1,330.355,490.355Zm-3.883,0a1.294,1.294,0,1,1,1.294-1.294A1.294,1.294,0,0,1,326.472,490.355ZM322.589,480h15.532a2.589,2.589,0,0,1,2.589,2.589v15.532a2.589,2.589,0,0,1-4.024,2.154l-3.236-2.154H322.589A2.589,2.589,0,0,1,320,495.532V482.589A2.589,2.589,0,0,1,322.589,480Zm0,15.532h11.649l3.883,2.589V482.589H322.589Z" transform="translate(-320 -480)"/>',
    // viewBox="0 0 20.71 20.709"
  };
  const pedidoNovoIcon = {
    name: 'pedido-novo-icon',
    style: 'line',
    path:
      '<path d="M386.589,482.589v12.944h11.124l4.409,2.589V482.589H386.589m0-2.589h15.533a2.589,2.589,0,0,1,2.589,2.589v15.532a2.589,2.589,0,0,1-3.9,2.232l-3.8-2.232H386.589A2.589,2.589,0,0,1,384,495.532V482.589A2.589,2.589,0,0,1,386.589,480Zm7.766,11.649h0a1.294,1.294,0,0,0-1.294,1.294h0a1.294,1.294,0,0,0,1.294,1.294h0a1.294,1.294,0,0,0,1.294-1.294h0A1.294,1.294,0,0,0,394.355,491.649Zm0-1.294h0a1.294,1.294,0,0,0,1.294-1.294v-3.883a1.294,1.294,0,0,0-1.294-1.294h0a1.294,1.294,0,0,0-1.294,1.294v3.883A1.294,1.294,0,0,0,394.355,490.355Z" transform="translate(-384 -480)"/>',
    // viewBox="0 0 20.71 20.709"
  };
  const devolverIcon = {
    name: 'devolver-icon',
    style: 'line',
    path:
      '<path d="M244.709,711.53a4.53,4.53,0,0,1-4.53,4.53H228.4l1.683,1.67a1.3,1.3,0,0,1-1.839,1.838l-3.883-3.883c-.012-.013-.012-.026-.025-.039a1.088,1.088,0,0,1-.247-.388.048.048,0,0,1-.013-.039,1.1,1.1,0,0,1-.078-.4v-.13a1.3,1.3,0,0,1,.065-.324.387.387,0,0,1,.052-.129,1.232,1.232,0,0,1,.246-.388l3.883-3.883a1.3,1.3,0,1,1,1.839,1.838l-1.683,1.67h11.778a1.941,1.941,0,1,0,0-3.883h-5.824a1.294,1.294,0,0,1-1.294-1.294h0A1.294,1.294,0,0,1,234.355,707h5.824a4.53,4.53,0,0,1,4.53,4.53Z" transform="translate(-223.999 -707)"/>',
    // viewBox="0 0 20.71 12.943"
  };
  const equipaIcon = {
    name: 'equipa-icon',
    style: 'line',
    path:
      '<path d="M596.71,366.243a6.472,6.472,0,0,0-2.848-5.365,5.177,5.177,0,1,0-7.507-7.119,5.178,5.178,0,1,0-7.507,7.119A6.472,6.472,0,0,0,576,366.243c0,.777.348,3.009,4.092,3.685.456,1.209,1.885,2.787,6.263,2.787s5.808-1.578,6.263-2.787C596.362,369.252,596.71,367.019,596.71,366.243Zm-3.883-9.061a2.589,2.589,0,0,1-1.329,2.246,5.158,5.158,0,0,0-2.837-4.271,2.541,2.541,0,0,1,1.578-.563A2.6,2.6,0,0,1,592.827,357.182Zm-6.472,5.177a2.589,2.589,0,1,1,2.589-2.589A2.589,2.589,0,0,1,586.355,362.36Zm-3.883-7.766a2.54,2.54,0,0,1,1.578.566,5.155,5.155,0,0,0-2.837,4.271,2.578,2.578,0,0,1,1.259-4.837Zm-2.384,12.7c-1.476-.379-1.5-1.051-1.5-1.051a3.866,3.866,0,0,1,3.338-3.829,5.2,5.2,0,0,0,.813,1.052,6.486,6.486,0,0,0-2.652,3.827Zm6.267,2.832c-3.883,0-3.883-1.294-3.883-1.294a3.883,3.883,0,1,1,7.766,0S590.238,370.126,586.355,370.126Zm6.267-2.832a6.487,6.487,0,0,0-2.652-3.827,5.193,5.193,0,0,0,.813-1.052,3.866,3.866,0,0,1,3.338,3.829S594.1,366.915,592.622,367.294Z" transform="translate(-576 -351.984)"/>',
    // viewBox="0 0 20.71 20.73"
  };
  
  export {
    homeIcon,
    registoIcon,
    gestaoIcon,
    operacoesIcon,
    documentacaoIcon,
    estatisticasIcon,
    noticiasIcon,
    loginIcon,
    menuIcon,
    arrowDownIcon,
    perfilIcon,
    contactoIcon,
    alterarPasswordIcon,
    terminarSessaoIcon,
    consultarIcon,
    criarIcon,
    alterarIcon,
    importarIcon,
    importarcsvIcon,
    exportarIcon,
    apiIcon,
    analiseIcon,
    usersIcon,
    adminIcon,
    zoomIcon,
    removeIcon,
    eliminarIcon,
    limparIcon,
    adicionarIcon,
    arrowBackIcon,
    arrowUpIcon,
    continuarIcon,
    descricaoIcon,
    folderIcon,
    decisaoIcon,
    validarIcon,
    guardarIcon,
    relogioIcon,
    passwordIcon,
    emailIcon,
    ccIcon,
    entidadeIcon,
    tipologiaEntIcon,
    recuperarPasswordIcon,
    cmdIcon,
    renovarApiIcon,
    legislacaoIcon,
    descriptionIcon,
    acceptIcon,
    closeAllIcon,
    expandAllIcon,
    lookIcon,
    pdfIcon,
    participacaoIcon,
    participadoIcon,
    editadoIcon,
    tsIcon,
    pedidoAprTecnIcon,
    pedidoAprovadoIcon,
    pedidoDespachoIcon,
    pedidoDevolvidoIcon,
    pedidoEmValidacaoIcon,
    pedidoNovoIcon,
    devolverIcon,
    equipaIcon,
  };
  